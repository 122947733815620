import * as Sentry from "@sentry/browser";
import { msg } from "common/stores/SnackbarStore";
import { GenericObject } from "common/types/Survey";
import ky, { KyResponse, Options } from "ky";
import { loginRedirect } from "modules/auth/AuthUtils";
import { publicPaths } from "modules/auth/RouteGuard";
import Router from "next/router";
import { SWRResponse } from "swr";

/**
 * Base class for Monarch server implementations. In implementations, please use the functions
 * defined here to make requests to the backend. Each function checks whether a monarch URL
 * exists, if it does not, it retrieves it, then proceeds with the request. If you would like
 * to use request methods aside from get/post/put, please add them here.
 */

// Handles 401 Unauthorized responses by redirecting to the login page.
// If this error occurs server-side, it will be handled by the caller instead
// since redirection might not be desirable in that context.
function handleUnauthorized() {
	const path = Router.asPath.split("?")[0];

	// Note: If the Router already has a returnUrl query parameter, ensure we don’t overwrite or duplicate it.
	let query;

	// Parse URL parameters manually since Router.query is unreliable with SingletonRouter
	const urlParams = new URLSearchParams(Router.asPath.split("?")[1]);

	// If the path isn't public, set the returnUrl query param to redirect back after login.
	if (!publicPaths.includes(path)) {
		query = { returnUrl: path };
	}

	// Allow parameters from the backend to pass to the login page if `error` is present.
	const errorParam = urlParams.get("error");

	// Special handling for survey paths: include specific query params in the return URL.
	// Router.query does not work here, so we use `urlParams`.
	if (path.includes("survey")) {
		query = {
			returnUrl: `${path}?replyId=${
				urlParams.get("replyId") ?? localStorage.getItem("replyId")
			}`,
			username: urlParams.get("username"),
		};
	}

	// Specific handling for `unauthorized` routes (e.g., expired or invalid links)
	if (Router.asPath.includes("unauthorized")) {
		query = { returnUrl: path };
	}

	// Redirect to the login page with relevant query parameters
	loginRedirect(
		"auth0/login",
		query.returnUrl,
		`/${errorParam ? `?error=${new URLSearchParams(errorParam)}` : ""}`,
		query.username
	);
}

function handleError(error): KyResponse | never {
	console.warn("MonarchClient handleError", { error });
	if (
		error?.name === "HTTPError" &&
		error.response?.status === 401 &&
		typeof window !== "undefined"
	) {
		handleUnauthorized();
		return error.response;
	}

	console.warn("MonarchClient handleError re-throwing");
	// re-throw so the caller can handle the error
	throw error;
}

export const swrErrorHandle = ({
	key,
	result,
	params = undefined,
	expectArray = false,
	userFacingError = false,
}: {
	key: string;
	result: SWRResponse;
	params?: GenericObject;
	expectArray?: boolean;
	userFacingError?: boolean;
}) => {
	const { data, error, isLoading } = result;
	let errorMessage = "";
	if (error) {
		errorMessage = `${error.message}: ${JSON.stringify({ key, params, result })}`;
	} else if (
		// !error && - check is redundant, earlier case ensures this
		!isLoading &&
		data &&
		expectArray &&
		!Array.isArray(data)
	) {
		// If we expect an array but get an object, this is likely an error response with a mis-matched response code, throw an error
		errorMessage = `${data.detail?.error}: ${JSON.stringify({ key, params, result })}`;
	}
	if (errorMessage) {
		if (
			[401, 403].includes(error?.response?.status) ||
			data?.detail?.error === "Authentication Error" ||
			errorMessage.includes("The operation was aborted")
		) {
			// Don't bother Sentry
			console.warn(errorMessage);
		} else {
			console.error(errorMessage);
			const err = new Error(errorMessage);
			Sentry.captureException(err);
		}
		if (userFacingError) {
			msg.set({
				message: data?.detail?.error || errorMessage,
				autoHide: false,
				severity: "error",
			});
		}
	}
};

export default class MonarchClient {
	static api = ky.extend({
		prefixUrl: process.env.MONARCH_URL,
		credentials: "include",
		timeout: 60 * 1000,
		hooks: {
			beforeError: [
				(error) => {
					const { response } = error;
					if (response?.status === 401) {
						// Handle the 401 error and redirect
						handleUnauthorized();
					}
					return error;
				},
			],
		},
	});

	static async get(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.get(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async post(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.post(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async put(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.put(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async delete(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.delete(endpoint, options)
			.catch((e) => handleError(e));
	}

	static async patch(
		endpoint: string | URL,
		options?: Options
	): Promise<KyResponse> {
		return MonarchClient.api
			.patch(endpoint, options)
			.catch((e) => handleError(e));
	}
}

export const prepareSearchParams = (searchParams) => {
	const urlSearchParams = new URLSearchParams();
	const filteredParams = prepareJson(searchParams);

	for (const [key, value] of Object.entries(filteredParams)) {
		if (Array.isArray(value)) {
			// Default behavior of URLSearchParams constructor when passed an array results in something like:
			// ?myArray=foo%2Cbar%2Cfizz%2Cbuzz (simply the result of .toString() on the array, with escaped comma characters)
			// Optera API expects duplicate keys, eg:
			// ?myArray=foo&myArray=bar&myArray=fizz&myArray=buzz
			// Order matters. Preserve it. (sorting params, etc)
			value.forEach((arrayValue) => {
				urlSearchParams.append(key, arrayValue);
			});
		} else {
			urlSearchParams.append(key, String(value));
		}
	}

	return urlSearchParams;
};

export const prepareJson = (searchParams) => {
	if (!searchParams) {
		return {};
	}
	// filter out nullish values from the search params
	const filteredSearchParams = Object.fromEntries(
		Object.entries(searchParams).filter(
			([, value]) =>
				value !== null &&
				value !== undefined &&
				value !== "" &&
				!(Array.isArray(value) && value.length === 0)
		)
	);

	Object.entries(filteredSearchParams).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			const filteredArray: Array<any> = [];
			value.forEach((val) => {
				if (val !== null && val !== undefined && val !== "") {
					filteredArray.push(val);
				}
			});
			if (filteredArray.length > 0) {
				filteredSearchParams[key] = filteredArray;
			} else {
				delete filteredSearchParams[key];
			}
		} else if (typeof value === "object") {
			// treat nested objects recursively
			filteredSearchParams[key] = prepareJson(value);
		} else if (typeof value === "number") {
			filteredSearchParams[key] = Number(value);
		} else {
			filteredSearchParams[key] = String(value);
		}
	});

	return filteredSearchParams;
};
