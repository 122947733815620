import React, { forwardRef, ReactElement, Ref } from "react";

import {
	FormControl,
	FormControlProps,
	FormHelperText,
	MenuItem,
	TextField,
} from "@mui/material";
import styles from "common/components/FormTextField/FormField.module.css";
import CheckIcon from "common/components/Icons/CheckIcon";
import ChevronDownIcon from "common/components/Icons/ChevronDownIcon";
import { GenericObject } from "common/types/Survey";
import {
	getAdornmentForInputProps,
	StartAdornment,
} from "common/utils/AdornmentUtils";

/**
 * if value is undefined will default to same as text
 * */
export interface IMenuItem {
	value?: string;
	text: string;
	disabled?: boolean;
}

export interface FormSelectProps
	extends Omit<
		FormControlProps,
		"onChange" | "defaultValue" | "margin" | "hiddenLabel" | "variant"
	> {
	menuItemList?: Array<IMenuItem>;
	label?: string;
	helperText?: string;
	onChange?: (event: { target: { value: string } }) => void;
	disabled?: boolean;
	error?: boolean;
	adornment?: StartAdornment;
	value?: string | number | string[];
	multiple?: boolean;
	size?: "small" | "medium";
	name?: string;
	testid?: string;
	native?: boolean;
	placeholder?: string;
	noBlankOption?: boolean; // this is somewhat similar in spirit though opposite in value to `displayEmpty` prop of MuiSelect
	children?: React.ReactNode;
}

export function transformObjectToMenuItemList(o: GenericObject): IMenuItem[] {
	let itemList = [];
	for (let [k, v] of Object.entries(o)) {
		itemList.push({ text: v, value: k });
	}
	return itemList;
}

function generateOptions(
	menuItemList: Array<IMenuItem>,
	native: boolean,
	noBlankOption: boolean
): ReactElement[] {
	if (native) {
		if (menuItemList.length == 0) {
			return [<option value="" key="nullOption"></option>];
		}
		let options = menuItemList.map((menuItem, i) => {
			const { value, text, disabled } = menuItem;
			return (
				<option
					key={`formSelectItem-${value ?? i}`}
					value={value ?? text} // the text in value fallback is to prevent translator plugins from having translated values sent in form values
					disabled={disabled || false}
				>
					{text}
				</option>
			);
		});
		if (noBlankOption) {
			return options;
		} else {
			return [<option value="" key="blank"></option>, ...options];
		}
	} else {
		if (menuItemList.length == 0) return;
		return menuItemList.map((menuItem) => {
			const { value, text, disabled } = menuItem;
			return (
				<MenuItem
					key={`formSelectItem-${value}`}
					value={value}
					title={text}
					disabled={disabled || false}
					sx={{
						backgroundColor: "var(--oc-palette-opteraBackground-main)",
						"& svg": {
							visibility: "hidden",
						},
						"&.Mui-selected > svg": {
							visibility: "visible",
							fontSize: "20px",
							right: 0,
							bottom: 0,
							position: "absolute",
						},
					}}
				>
					{text}
					<CheckIcon />
				</MenuItem>
			);
		});
	}
}
/**
 * A wrapper around MuiSelect with FormControl, InputLabel and HelperText. Default is to use native select element for improved accessibility, and testability
 * if using noBlankOption a value should be provided. UI will appear to have first entry in menuItemList selected, but onChange will never have triggered
 */
function FormSelect(
	{
		label,
		helperText,
		menuItemList = [],
		onChange,
		adornment,
		// multiple needs to not be in ...props
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		multiple,
		value,
		name,
		testid,
		native = true,
		noBlankOption = false,
		children,
		error,
		...props
	}: FormSelectProps,
	ref: Ref<HTMLDivElement>
) {
	const htmlId = `select-${(label || name || helperText || "unknown").replace(
		" ",
		"-"
	)}`;

	return (
		<FormControl {...props}>
			{label && (
				<label
					id={`${htmlId}-label`}
					htmlFor={htmlId}
					className={styles.label + (props.disabled ? " disabled" : "")}
				>
					{label}
				</label>
			)}
			<TextField
				disabled={props.disabled}
				error={error}
				value={value}
				id={htmlId}
				data-testid={testid || name}
				select
				SelectProps={{
					native: native,
					IconComponent: () =>
						ChevronDownIcon({
							color: props.disabled
								? "var(--oc-palette-text-disabled)"
								: "var(--oc-palette-text-primary)",
						}),
					sx: {
						"& .MuiSelect-select": {
							pt: "10px",
						},
					},
				}}
				onChange={onChange}
				sx={{
					...props.sx,
					height: "40px",
					// All this *might* be better in the OpteraTheme file
					// the only thing stopping me from doing that is that there are conditionals
					"& .Mui-disabled": {
						color: "var(--oc-palette-text-disabled)",
					},
					"& .MuiOutlinedInput-input > svg": {
						visibility: "hidden",
					},
					"& .MuiOutlinedInput-root": {
						height: "40px",
						bgcolor: props.disabled
							? "var(--oc-palette-secondary-100)"
							: "var(--oc-palette-opteraBackground-main)",
						color: "var(--oc-palette-text-primary)",
						"&.MuiOutlinedInput-root > svg": {
							position: "absolute",
							right: 0,
							pointerEvents: "none",
							bottom: 0,
						},
						"& fieldset": {
							borderRadius: 1,
							border: "1px solid",
							borderColor: error
								? "var(--oc-palette-error-200)"
								: "var(--oc-palette-secondary-200)",
						},
						"&.Mui-focused fieldset": {
							boxShadow: error
								? "0px 0px 0px 4px var(--oc-palette-error-box-shadow)"
								: "0px 0px 0px 4px var(--oc-palette-primary-box-shadow)",
							borderColor: error
								? "var(--oc-palette-error-200)"
								: "var(--oc-palette-primary-500)",
						},
					},
				}}
				size="medium"
				focused={props.focused}
				autoComplete="off"
				name={name}
				InputProps={
					adornment
						? getAdornmentForInputProps(adornment, props.disabled)
						: undefined
				}
				ref={ref}
			>
				{children
					? children
					: generateOptions(menuItemList, native, noBlankOption)}
			</TextField>
			{helperText ? (
				<FormHelperText
					error={error}
					className={styles.helperText + (error ? " error" : "")}
					disabled={props.disabled}
				>
					{helperText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
}

export default forwardRef(FormSelect);
