import { Button } from "@mui/material";
import FormSelect from "common/components/FormSelect/FormSelect";
import OpteraModal from "common/components/Modal";
import EnergieClient, {
	GWP_VERSIONS,
	OrgPreferences,
	useOrganizationPreferences,
} from "modules/facade/EnergieClient";
import { useEffect } from "react";
import {
	Controller,
	SubmitErrorHandler,
	SubmitHandler,
	useForm,
} from "react-hook-form";

interface EditOrgPreferencesModalProps {
	onClose: () => void;
	open: boolean;
}

const onInvalidSubmit: SubmitErrorHandler<OrgPreferences> = (data) => {
	console.warn("Invalid Submit", { data });
};

function EditOrgPreferencesModal({
	onClose,
	open,
}: EditOrgPreferencesModalProps) {
	const { data: preferences } = useOrganizationPreferences();
	const {
		control,
		formState: { errors },
		handleSubmit,
		reset,
	} = useForm({ defaultValues: preferences });

	useEffect(() => {
		reset(preferences);
	}, [preferences, reset]);

	const onValidSubmit: SubmitHandler<OrgPreferences> = async (data) => {
		EnergieClient.patchOrganizationPreferences(data);
		onClose();
		window.location.reload();
	};

	return (
		<OpteraModal
			title="Edit Organization Preferences"
			themeColor="primary"
			ariaPrefix="edit-organization-preferences"
			open={open}
			onClose={onClose}
			actionButton={
				<Button
					variant="contained"
					form="edit-organization-preferences"
					type="submit"
				>
					Submit
				</Button>
			}
		>
			<form
				id="edit-organization-preferences"
				onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}
			>
				<p className="body1">Edit your organization&apos;s preferences.</p>
				{Object.keys(errors).length !== 0 && (
					<p className="body2 mt-1 text-error">
						Please complete all required questions.
					</p>
				)}
				<Controller
					name="inventory_start_month"
					control={control}
					rules={{
						required: {
							value: true,
							message: "This is a required field.",
						},
					}}
					render={({ field, fieldState: { error } }) => (
						<FormSelect
							label="Inventory Start Month"
							menuItemList={[
								"January",
								"February",
								"March",
								"April",
								"May",
								"June",
								"July",
								"August",
								"September",
								"October",
								"November",
								"December",
							].map((v, i) => ({ text: v, value: `${i + 1}` }))}
							noBlankOption
							error={!!error}
							{...field}
						/>
					)}
				/>
				<p className="subtitle2 mt-3 mb-2">GWP Version</p>
				<Controller
					name="gwp_version"
					control={control}
					rules={{
						required: {
							value: true,
							message: "This is a required field.",
						},
					}}
					render={({ field, fieldState: { error } }) => (
						<FormSelect
							label="GWP Version"
							menuItemList={GWP_VERSIONS.map((v) => ({ text: v }))}
							noBlankOption
							error={!!error}
							{...field}
						/>
					)}
				/>
			</form>
		</OpteraModal>
	);
}

export default EditOrgPreferencesModal;
