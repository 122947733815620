import {
	BulkUploadResponse,
	CreateDisclosureRequest,
	Disclosure,
	EntitiesResponse,
	Report,
	ReportRequest,
} from "common/types/ESGInsightsUpload";
import { Page, PageMeta, PageSearchParams } from "common/types/Monarch";
import {
	SummaryData,
	SupplierDetailsResponse,
	SupplyChainListingResponse,
	VendorDetailsResponse,
} from "common/types/SupplyChain";
import { GenericObject } from "common/types/Survey";
import { Options } from "ky";
import MonarchClient, {
	prepareJson,
	prepareSearchParams,
	swrErrorHandle,
} from "modules/facade/MonarchClient";
import {
	BaseConsumptionItem,
	SimpleConsumptionItem,
} from "pages/prototype/m3/consumption";
import slimObjectHash from "slim-object-hash";
import useSWR, { mutate, SWRConfiguration } from "swr";
import useSWRImmutable from "swr/immutable";

const BASE_API_PREFIX = "relay/energie/";
const SUPPLY_CHAIN_PREFIX = `${BASE_API_PREFIX}supply-chain/`;

type ActivityUnitEnum = "kWh" | "MWh" | "MMBtu" | "Liters" | "Gal" | string;
export type EmissionMethodEnum = "location-based" | "market-based" | "default";
export type ActivityCategoryEnum =
	| "s1.1"
	| "s1.2"
	| "s1.3"
	| "s1.4"
	| "s2.1"
	| "s2.2"
	| "s2.3"
	| "s3.1"
	| "s3.2"
	| "s3.3"
	| "s3.4"
	| "s3.5"
	| "s3.6"
	| "s3.7"
	| "s3.8"
	| "s3.9"
	| "s3.10"
	| "s3.11"
	| "s3.12"
	| "s3.13"
	| "s3.14"
	| "s3.15"
	| "oos.1"
	| "oos.2";

type UnitValue = {
	unit: string;
	magnitude: number;
};

interface ResourceEmissionFactor {
	[key: string]: EmissionFactor;
}

interface EmissionFactor {
	magnitude: number;
	activity_unit: ActivityUnitEnum;
	emission_unit: "mt" | "kg" | "g" | "L" | "kg/l" | string;
}

export interface EmissionFactors {
	CO2?: ResourceEmissionFactor;
	CH4?: ResourceEmissionFactor;
	N2O?: ResourceEmissionFactor;
	[key: string]: ResourceEmissionFactor | undefined;
}

export interface ConsumptionResource extends UnitValue {
	unit: ActivityUnitEnum;
	formatted?: string;
	resource?: string;
	in_base_unit?: number;
}

export interface GreenhouseGasProfile extends UnitValue {
	co2e: number;
	factors: ResourceEmissionFactor;
	gwp: number;
}

export interface EmissionsComposition {
	CH4: GreenhouseGasProfile;
	CO2: GreenhouseGasProfile;
	N2O: GreenhouseGasProfile;
	total_co2e: number;
	metadata: any[];
}

export interface EmissionFactorBreakdown {
	volume_fuel_combusted?: ConsumptionResource;
	electricity_purchased?: ConsumptionResource;
	mass_gas_emitted?: ConsumptionResource;
	heat_content?: ConsumptionResource;
	mass_fuel_combusted?: ConsumptionResource;
	distance_traveled?: ConsumptionResource;
	volume_gas_emitted?: ConsumptionResource;
	mass_refrigerant_leaked?: ConsumptionResource;
	volume_refrigerant_leaked?: ConsumptionResource;
	purchaser_price?: ConsumptionResource;
}

export interface NewSimpleConsumptionItem {
	consumption_uuid: string;
	starts_at: string;
	ends_at: string;
	emissions_factors: {
		[key in EmissionMethodEnum]?: EmissionFactors;
	};
	resources: ConsumptionResource[];
	activity_uuid: string;
	facility: string;
}

export interface MetricValue {
	measured_at_period: string; // typically something like year[2020]
	updated_by: string; // usually the user's email
}

// From ingot/schemas/allocations.py
export type DataQuality = "supplier-specific" | "modeled";
export type SupplierMethodology =
	| "supplier-specific"
	| "modeled"
	| "category average emissions"
	| "supplier-specific + modeled emissions";

export interface EmissionIntensityMetricValue extends MetricValue {
	length?: number;
	value?: number;
	in_base_unit?: number;
	metadata?: {
		industry: string;
		activity: string;
		region: string;

		// per scope
		// source
		scope_1_source: string;
		scope_2_market_source?: string;
		scope_2_location_source?: string;
		scope_3_source: string;

		// intensity
		scope_1_intensity: number;
		scope_2_market_intensity: number;
		scope_2_location_intensity: number;
		scope_3_intensity: number;
		// data quality
		scope_1_data_quality: DataQuality;
		scope_2_market_data_quality?: DataQuality;
		scope_2_location_data_quality?: DataQuality;
		scope_3_data_quality: DataQuality;
		// used emissions or intensity entered directly
		scope_1_data_type: "direct-intensity" | "revenue-based";
		scope_2_market_data_type?: "direct-intensity" | "revenue-based";
		scope_2_location_data_type?: "direct-intensity" | "revenue-based";
		scope_3_data_type: "direct-intensity" | "revenue-based";
		modified_at: string; // iso datetime,
		supplier_methodology: SupplierMethodology;
		scope_3_used: boolean; // whether scope 3 is used in calculations
		// both cannot be true. If so, default to market
		scope_2_market_used: boolean; // whether s2 market is used in calculations
		scope_1_percent_verified: number;
		scope_2_market_percent_verified: number;
		scope_2_location_percent_verified: number;
		scope_3_percent_verified: number;
		naics_code?: string;
		emission_factor_name?: string;
		source?: string;
		date_modified?: string;
	};
}

export interface EmissionsMetricValueBreakdown {
	s1?: number;
	s2?: number;
	"s2:location-based"?: number;
	"s2:market-based"?: number;
	s3?: number;
}

// from ingot allocations.py
export interface EmissionsMetricValue extends MetricValue {
	value?: EmissionsMetricValueBreakdown;
	metadata?: {
		date_modified: string;
		scope_1_source: string;
		scope_2_market_source: string;
		scope_2_location_source: string;
		scope_3_source: string;
		modified_at: string; // datetime iso string
		industry: string;
		activity: string;
		region: string;
		source: string;
	};
}

// Defined in the front-end function "addCalculationNotes"
export interface NotesMetricValue extends MetricValue {
	// metric_name === notes_calculation
	value?: {
		notes: string;
	};
	metadata?: {
		source: "Calculation Notes";
	};
}

// Defined in the front-end function "addCalculationNotes"
export interface ContactMetricValue extends MetricValue {
	// metric_name === notes_calculation
	value?: {
		name: string;
		email: string;
	};
	metadata?: {
		source: "Contact Form";
	};
}

export interface RevenueMetricValue extends MetricValue {
	value?: number;
	in_base_unit?: number;
	metadata?: {
		source: string;
		modified_at: string; // datetime iso string
		revenue_source?: string;
		organization_id?: number;
	};
	conversion?: {
		value: number;
		unit: string;
		rate: number;
		source: "IRS";
	};
}

// Matches the model in energie responses.py
export interface Metric {
	prev_value?: number;
	name: string; // "emissions" | "revenue"
	unit: string; // "CAD" | "USD" | "metric_ton" | etc
	measurement_interval: string; // "year"
	value?: number;
	values?: (
		| EmissionsMetricValue
		| RevenueMetricValue
		| EmissionIntensityMetricValue
		| NotesMetricValue
	)[];
	// energie currently declares period_value: Optional[Any] = None, however it should be a MetricValue
	period_value?:
		| EmissionsMetricValue
		| RevenueMetricValue
		| EmissionIntensityMetricValue
		| NotesMetricValue;
}

export type TagType =
	| "facility"
	| "site"
	| "vendor"
	| "supplier"
	| "commodity"
	| "fuel"
	| "region"
	| "business_unit";

export interface Tag<T = GenericObject> {
	name: string; // === `${type}:${value}`
	type: TagType;
	value: string;
	tag_metadata: T;
	metrics?: Metric[];
	related_tags?: string[];
	system_flags?: SystemFlag[];
	emissions?: {
		[key in EmissionMethodEnum]?: UnitValue;
	};
}

type TagConnectionRequestItem =
	| { tag: string; ref_id?: never; uuid?: never }
	| { ref_id: string; tag?: never; uuid?: never }
	| { uuid: string; tag?: never; ref_id?: never };

export interface Intensity {
	floor_area?: Metric;
	unit: string;
	value: number;
}

export const GWP_VERSIONS = ["AR4", "AR5", "AR6"] as const;
export type GwpVersion = (typeof GWP_VERSIONS)[number];
export interface TagEmissionsRankingParams {
	period_pk: string; // formatted like "year[2022]" pattern: ^((year\[\d{4}\])|(quarter\[\d{4}\.0?[1234]\])|(month\[\d{4}\.((0?[1-9])|(1[0-2]))\]))$
	highlight?: string; // Specifies the record to highlight (either 'top' or a specific tag value)
	n?: string; // Specifies the number of records to return
	by: string; // Determines the ranking metric
	market_based?: boolean;
	scopes?: ("1" | "2" | "3")[];
	gwp?: GwpVersion;
}

export interface TagEmissionsRankingResponse {
	percentage_total_emission?: number;
	prev_percentage_total_emission?: number;
	period_pk: string;
	prev_rank: number;
	rank: number;
	tag_name: string;
	tag_title: string;
	prev_total_emission: number;
	total_emission: number;
	intensities: Intensity;
	// emissions: ?
}
export interface FacilityTagMetadata extends GenericObject {
	title: string;
	country: string;
	facility_type: string;
	street_address?: string;
	locality?: string;
	administrative_area?: string;
	postal_code_short?: string;
	// egrid region? If deriving, need at least postal_code_short, and potentially the activity utility provider
	us_egrid?: string;

	// Do these still exist?
	// 	site_name: facilityData.tag_metadata.site_name,
	// 	open_date: facilityData.tag_metadata.open_date,
	// 	close_date: facilityData.tag_metadata.open_date,
	// 	// not explicitly defined in BE model:
	// 	address_line2: facilityData.tag_metadata.address_line2,
}

export interface DateRangeResponse {
	first_consumption_date: string;
	last_consumption_date: string;
}

export type ConsumptionOutputData = {
	resources: EmissionFactorBreakdown;
};

export interface RenewableImpactData {
	electricity: UnitValue & {
		renewables_rate: number;
		renewables_instruments: string[];
	};
}

export interface ActivityConsumptionEmissions {
	default?: EmissionsComposition;
	"location-based"?: EmissionsComposition;
	"market-based"?: EmissionsComposition;
}

// POST & PUT (create & edit) request:
interface ConsumptionEditRequest {
	starts_at: string;
	ends_at: string;
	actual: boolean;
	resources: {
		[key: string]: UnitValue;
	};
	ref_id: string;
	modeled_data_uuid?: string; // is this required? API docs are unclear on this request schema.
}

// POST & PUT (create & edit) and GET consumption response:
export interface ConsumptionOutput {
	uuid?: string;
	ref_id: string;
	activity_uuid?: string;
	actual: boolean;
	consumption_data: ConsumptionOutputData;
	consumptions?: RenewableImpactData;
	starts_at: string; // ISO-8601 datetime
	ends_at: string; // ISO-8601 datetime
	emissions: ActivityConsumptionEmissions;
}

// GET Activity Consumptions response:
export interface Consumption {
	uuid?: string;
	ref_id: string;
	activity_uuid?: string;
	actual: boolean;
	consumption_data: ConsumptionOutputData;
	consumptions?: RenewableImpactData;
	starts_at: string; // ISO-8601 datetime
	ends_at: string; // ISO-8601 datetime
	emissions: ActivityConsumptionEmissions;
}

export type FlagEntityType = "activities" | "tags";
export type FlagType = "missing_emission_factor" | "missing_consumption";

export interface SystemFlag {
	uuid: string; // uuid
	organization_id: number;
	period_pk: string;
	entity_pk: string; // Activity.uuid or Tag.name
	entity_type: FlagEntityType;
	flag_type: FlagType;
	flag_data: {
		// JSONB
		determinants?: DeterminantKey[];
		determinants_metadata?: Determinant[];
		gaps?: string[][];
		key_determinants?: DeterminantKey[];
		outcome_code: OutcomeCode;
		total_missing_days?: number;
	};
	description: string; // computed. should this be computed on FE instead of BE?
	description_html: string; // computed. should this be computed on FE instead of BE?
}

export interface BaseActivity {
	uuid: string;
	ref_id: string;
	label?: string;
	category: ActivityCategoryEnum;
	emissions_source?: string;
	active_at?: string; // ISO-8601 datetime
	retired_at?: string;
	traits: ActivityTraits;
	location_traits?: Array<string>;
	emissions?: {
		[key in EmissionMethodEnum]?: UnitValue;
	};
	system_flags?: SystemFlag[];
}

// GET response:
export interface ActivityWithTags extends BaseActivity {
	tags?: Tag[];
	resolved_system_flags?: SystemFlag[];
	consumption_stats?: {
		modeled_days: number;
		total_days: number;
		breakdown: ConsumptionBreakdown;
	};
	emissions: ConsumptionBreakdown;
}

// TODO: consider new name for this, as it is used for emissions_stats too.
export type ConsumptionBreakdown = {
	[key in EmissionMethodEnum]?: UnitValue & {
		in_period_total: number;
	};
};

// List response:
export interface Activity extends BaseActivity {
	tags: string[]; // eg ["facility:glasgow", "commodity:electricity"]
}

export interface ActivityChangeItem {
	category: ActivityCategoryEnum;
	tags: { [key: string]: string };
	active_at: string | null; // ISO-8601 datetime
	retired_at: string | null; // ISO-8601 datetime
	ref_id: string;
	label?: string;
}

export interface ActivityChangeRequest {
	activity: ActivityChangeItem;
	period: string; // ex: "year[2022]"
	include_resolved_flags?: boolean;
}

// Unused - see getTagsEmissionsHistory
interface FacilityArea {
	unit: string;
	value: number;
	formatted: string;
	metric: {
		unit: string;
		value: number;
	};
}

export interface FacilityMetric {
	name: string;
	title: string;
	count?: number;
}

// Unused - see getTagsEmissionsHistory
interface Facility {
	tag_name: string;
	emissions: EmissionHistoryResponseItem[];
	intensities: {
		floor_area: FacilityArea;
	};
}

export interface ActivitiesListResponseMeta extends PageMeta {
	tags?: {
		[key: string]: {
			metadata: {
				country: string;
				facility_type: string;
				title: string;
			};
			type: string;
		};
	};
}

export interface VendorAllocatedEmissionsItem {
	vendor: string;
	commodity: string;
	consumptions: {
		purchaser_price: UnitValue;
	};
	emissions: {
		unit: string;
		total: number;
		breakdown: {
			s1: number;
			s3: number;
			["s2:market-based"]: number;
			["s2:location-based"]: number;
		};
	};
}
export interface NAICSCodesList {
	key: string;
	geographic_scope: string;
	json_schema: {
		oneOf: NAICSCodesItem[];
	};
}

export interface NAICSCodesItem {
	const: string;
	title: string;
}

export interface EmissionsAllocationItem {
	industry?: string;
	activity?: string;
	region?: string;

	revenue?: number;
	revenue_source?: string;
	revenue_currency?: string;

	scope_1_emissions?: number;
	scope_1_intensity_value?: number;
	scope_1_source?: string;
	scope_1_data_quality?: SupplierMethodology;
	scope_1_percent_verified?: number;

	scope_2_market_emissions?: number;
	scope_2_market_intensity_value?: number;
	scope_2_market_source?: string;
	scope_2_market_data_quality?: SupplierMethodology;
	scope_2_market_percent_verified?: number;

	scope_2_location_emissions?: number;
	scope_2_location_intensity_value?: number;
	scope_2_location_data_quality?: SupplierMethodology;
	scope_2_location_source?: string;
	scope_2_location_percent_verified?: number;

	scope_3_emissions?: number;
	scope_3_intensity_value?: number;
	scope_3_source?: string;
	scope_3_data_quality?: SupplierMethodology;
	scope_3_percent_verified?: number;
	scope_3_used: boolean; // whether scope 3 is used in calculations

	// both cannot be true. If so, default to market
	scope_2_market_used: boolean; // whether s2 market is used in calculations
}

export interface FactorItem {
	// Can be a multitude of gas types
	[key: string]: {
		purchaser_price: {
			activity_unit: string;
			emission_unit: string;
			magnitude: string;
		};
	};
}

export interface NAICSEmissionFactorData {
	agency: string;
	category: string;
	data_year: number;
	dataset: string;
	determinants: {
		naics_code: string[];
	};
	factors: FactorItem;
	geographic_scope: string;
	method: string;
	release_month: number;
	release_year: number;
	resources: string[];
}

interface EmissionHistorySearchParams {
	period_pk: string;
	relative_duration?: number;
	gwp?: string;
	market_based?: boolean;
	breakdown?: ("scope" | "resource" | "category")[];
	scopes?: ("1" | "2" | "3")[];
	categories?: string;
	resources?: string;
}

interface TagEmissionsHistorySearchParams extends EmissionHistorySearchParams {
	include_metrics?: boolean;
}

// unused - see getTagsEmissionsHistory
interface TagsEmissionsHistorySearchParams
	extends TagEmissionsHistorySearchParams,
		PageSearchParams {
	sort_by?: "-intensity.floor_area" | "-total_emission";
}

export interface EmissionHistoryResponseItem {
	period_pk: string;
	starts_at: string;
	ends_at: string;
	total_emission: number;
	breakdown?: {
		scope?: {
			1?: number;
			2?: number;
			3?: number;
		};
		category?: {
			[key: string]: number;
		};
		resource?: {
			[key: string]: number;
		};
	};
	[key: string]: any;
}

export interface SystemFlagsSummaryResponse {
	period: string;
	completeness: {
		days_present: number;
		days_modeled: number;
		days_expected: number;
	};
	system_flags: {
		activities?: number;
	};
}

export type DeterminantKey =
	| "facility"
	| "facility_type"
	| "title"
	| "gas"
	| "fuel"
	| "vehicle_subtype"
	| "vehicle_type"
	| "vehicle_year"
	| "country"
	| "us_egrid"
	| "naics_code"
	| "ca_province"; // should match "administrative_area" from the facility tag

type DeterminantKeyMapping = {
	facility: string;
	facility_type: string;
	title: string;
	gas: string;
	fuel: string;
	vehicle_subtype: string;
	vehicle_type: string;
	vehicle_year: string | number; // This key is explicitly of type number, but BE now accepts both formats
	country: string;
	us_egrid: string;
	naics_code: string;
	ca_province: string; // should match "administrative_area" from the facility tag
};

export type ActivityTraits = {
	[key in keyof DeterminantKeyMapping]?: DeterminantKeyMapping[key];
} & {
	[key: string]: any;
};

export interface SchemaOneOfOption {
	const: string;
	title: string;
}

export interface DeterminantSchema {
	oneOf?: SchemaOneOfOption[];
	type: string;
	minimum?: number;
}

export interface Determinant {
	geographic_scope: "global" | "national" | "regional" | string; // probably an Enum? , "local" ?
	key: DeterminantKey;
	title?: string;
	classification: string;
	json_schema?: DeterminantSchema;
}

export interface CategoryDeterminants {
	combinations: Array<string[]>;
	determinant_definitions: {
		[key in DeterminantKey]?: Determinant;
	};
}

export interface EmissionFactorInsightsRequest {
	category: ActivityCategoryEnum;
	activity_traits: ActivityTraits;
	method?: "default"; // probably an enum, other options 'market-based' | 'location-based'?
}

// From oc-emissions-chain class OutcomeCode
export type OutcomeCode =
	| 1 // best_outcome
	| 2 // potential_improvement
	| 3 // no_results_invalid_values
	| 4 // no_results_no_matching_values
	| 5 // no_results_missing_determinants
	| 6; // no_results

export interface EmissionFactorInsightResponse {
	description: string;
	determinants?: Determinant[];
	key_determinants?: Determinant[];
	outcome_code: OutcomeCode;
	// results contains emission factors (if any). Should get results when the outcome code is 1 (best possible outcome) or 2 (potential improvement)
	results: any[];
	selected_determinants?: Determinant[];
	// Haven't seen a value in this field yet
	validation_errors?: unknown;
}

export interface ConsumptionInsightsRequest {
	consumption_data: GenericObject;
	period: string; // ex "year[2028]"
}

export interface ConsumptionInsightsResponse {
	resources: [string[]];
	resources_metadata: {
		[key: string]: {
			dimensionality: string;
			title: string;
		};
	};
	validation_errors: InsightsValidationError[];
}

interface InsightsValidationError {
	input: unknown;
	loc: string[];
	msg: string;
	type: string;
	url: string;
	[key: string]: any;
}

type InventoryPeriodInterval = "year" | "quarter" | "month";

export interface InventoryPeriodItem {
	pk: string;
	starts_at: string; // ISO-8601 date string
	ends_at: string; // ISO-8601 date string
	interval: InventoryPeriodInterval;
	label: string; // ex "2022 (Jan 01 - Dec 31)"
}

// this mirrors the BE model, but yet unused in FE
export interface EmissionsReductionInstrumentMetadata {
	unique_id?: string;
	provider?: string;
	purchase_date?: string;
	purchase_group_name?: string;
	eac_name?: string; // AKA "project_name", EAC === Energy Attribute Certificate
	renewable_source?: string; // AKA "energy_type"
	retirement_data?: string;
	additional_notes?: string;
	[key: string]: any; // model_config: extra="allow"
}

// this mirrors the BE model, but yet unused in FE
export interface EmissionsReductionInstrument {
	ref_id: string;
	type: string;
	quantity: number;
	unit: string;
	eri_metadata?: EmissionsReductionInstrumentMetadata;

	uuid: string;
	organization_id: number;
	issued_at: string; // datetime
	effective_from: string; // datetime
	effective_to: string; // datetime
	s3_object_key?: string;
	allocation_methodology: string; // default="location"
	allocation_methodology_metadata?: unknown; // JSONB

	// relationships:
	emissions_reduction_allocations: unknown[]; // EmissionsReductionAllocation[]
	// known tag types include: ["Activity", "Facility", "Site", "Egrid", "State", "Province", "Country"]
	// see class LocationPriority(Enum) in energie/schemas/renewables.py
	tags: Tag[];
}

type EmissionsReductionInstrumentType =
	| "bundled_eac"
	| "unbundled_eac"
	| "bundled_rec"
	| "unbundled_rec"
	| "bundled_go"
	| "unbundled_go"
	| "bundled_rego"
	| "unbundled_rego"
	| "bundled_t_rec"
	| "unbundled_t_rec"
	| "bundled_i_rec"
	| "unbundled_i_rec"
	| "ppa"
	| "onsite_solar"
	| "onsite_wind"
	| "offsite_solar"
	| "offsite_wind";

export interface FacilityAllocations {
	allocated_amount: number;
	allocated_amount_mwh: number;
	allocation_percentage: number;
	facility: string;
}
export interface AllocationDetails {
	facility_allocations: Array<FacilityAllocations>;
	total_activities: number;
	total_allocated: number;
	total_facilities: number;
}
export interface RenewableResponse {
	ref_id: string;
	type: EmissionsReductionInstrumentType | null;
	quantity: number;
	eac_name?: string; // EAC === Energy Attribute Certificate
	project_name: string;
	energy_type: string;
	country_of_purchase: string;
	period_pk: string;
	allocation_details: AllocationDetails;
	issued_at: string;
	unique_id: string;
	unit: string;
	purchase_group_name: string;
	effective_from: string;
	effective_to: string;
}

export type RenewablesSummarySearchParams = {
	period_pk: string;
	tag_name?: string;
};
export interface RenewableSummaryResponse {
	period_pk: string;
	tag_name?: string;
	unit: "MWh";
	total_consumption: number;
	renewable_consumption: number;
	non_renewable_consumption: number;
	renewable_impact_percentage: number;
	facilities_with_renewables: number;
	total_facilities: number;
	facilities_with_renewables_percentage: number;
}

export type UploadType =
	| "activities"
	| "consumptions"
	| "emissions-factors"
	| "facilities"
	| "metrics"
	| "renewables"
	| "sites"
	| "tags";

export interface GetTagsRequestParams {
	period?: string; // ^((year\[\d{4}\])|(quarter\[\d{4}\.0[1234]\])|(month\[\d{4}\.((0[1-9])|(1[0-2]))\])|(day\[\d{4}\.((0[1-9])|(1[0-2]))\.([0-3][0-9])\]))$
	has_related_tag?: string;
	include_renewable_stats?: boolean;
	page?: number;
	page_size?: number;
	sort_by?: string;
	scope?: string;
	include?: "emissions";
}

export interface OrgPreferences {
	inventory_start_year: number;
	inventory_start_month: number;
	display_range_bounds: "[]" | "[)" | "()" | "(]";
	gwp_version: GwpVersion;
}

class EnergieClient {
	static url = process.env.FLAGSTAFF_URL;

	static async uploadCsv(
		type: UploadType,
		csv_file: File,
		params?: {
			delimiter?: string; // default ','
			source?: unknown;
			use_worker?: boolean; // default true;
			allow_update?: boolean; // default true;
			skip_post_upsert_hooks?: boolean; // default false;
		}
	): Promise<BulkUploadResponse> {
		let body = new FormData();
		body.append("csv_file", csv_file);
		Object.entries(prepareJson(params)).forEach(
			([key, value]: [key: string, value: string | Blob]) => {
				body.append(key, value);
			}
		);

		return MonarchClient.post(`${BASE_API_PREFIX}${type}/upload`, {
			body,
		}).then((res) => res.json());
	}

	static async getMetrics(
		searchParams?: {
			tag_name?: string;
			tag_type?: string;
		},
		options?: Options
	): Promise<FacilityMetric[]> {
		return MonarchClient.get(`${BASE_API_PREFIX}metrics`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	// Only used in prototype m3
	static async getConsumptions(
		startDate: string,
		endDate: string,
		maxRows: number,
		options?: Options
	): Promise<BaseConsumptionItem[]> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}consumptions?start=${startDate}&end=${endDate}&limit=${maxRows}`,
			options
		).then((res) => res.json());
	}

	// Only used in prototype m3
	static async getSimpleConsumptions(
		startDate: string,
		endDate: string,
		maxRows: number,
		category?: string,
		options?: Options
	): Promise<SimpleConsumptionItem[]> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}consumptions?start=${startDate}&end=${endDate}&limit=${maxRows}${
				category ? "&category=" + category : ""
			}`,
			options
		).then((res) => res.json());
	}

	static async deleteSimpleConsumption(uuid: string, options?: Options) {
		return MonarchClient.delete(
			`${BASE_API_PREFIX}consumptions?consumption_uuid=${uuid}`,
			options
		);
	}

	static async getActivitiesSummary(searchParams: {
		period: string;
		scopes: ("1" | "2" | "3")[];
	}): Promise<{
		emissions: {
			[key in EmissionMethodEnum]: UnitValue;
		};
	}> {
		return MonarchClient.get(`${BASE_API_PREFIX}activities/summary`, {
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getActivities(
		searchParams?: {
			scope?: string;
			period?: string; // ^((year\[\d{4}\])|(quarter\[\d{4}\.0[1234]\])|(month\[\d{4}\.((0[1-9])|(1[0-2]))\])|(day\[\d{4}\.((0[1-9])|(1[0-2]))\.([0-3][0-9])\]))$
			category?: ActivityCategoryEnum | "";
			sort_by?:
				| "-system_flags"
				| "system_flags"
				| "-label"
				| "label"
				| "-facility"
				| "facility";
			search_term?: string;
			include?: ("system_flags" | "emissions")[];
		} & PageSearchParams,
		options?: Options
	): Promise<Page<Activity, ActivitiesListResponseMeta>> {
		return MonarchClient.get(`${BASE_API_PREFIX}activities`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getActivity(
		id: string,
		searchParams?: {
			period?: string;
		},
		options?: Options
	): Promise<ActivityWithTags> {
		return MonarchClient.get(`${BASE_API_PREFIX}activity/${id}`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async putActivity(
		id: string,
		json: ActivityChangeRequest,
		options?: Options
	): Promise<ActivityWithTags> {
		// AWS API Gateway throws CORS errors upon receiving URL params in PUT or POST requests, so had to move searchParams into json
		return MonarchClient.put(`${BASE_API_PREFIX}activity/${id}`, {
			json,
			...options,
		}).then((res) => res.json());
	}

	static async putActivityTraits(
		id: string,
		json: ActivityTraits,
		options?: Options
	): Promise<any> {
		return MonarchClient.put(`${BASE_API_PREFIX}activity/${id}/traits`, {
			json,
			...options,
		}).then((res) => res.json());
	}

	static async getActivityConsumptions(
		id: string,
		searchParams?: {
			period?: string;
			include?: "emissions";
		},
		options?: Options
	): Promise<{ items: Consumption[] }> {
		return MonarchClient.get(`${BASE_API_PREFIX}activity/${id}/consumptions`, {
			...options,
			searchParams,
		}).then((res) => res.json());
	}

	static async getConsumptionByUUID(
		uuid: string,
		searchParams?: {
			market_based?: boolean;
		},
		options?: Options
	): Promise<Consumption> {
		return MonarchClient.get(`${BASE_API_PREFIX}consumptions/${uuid}`, {
			...options,
			searchParams,
		}).then((res) => res.json());
	}

	static async putActivityConsumption(
		activityId: string,
		consumptionId: string,
		json: ConsumptionEditRequest,
		options?: Options
	): Promise<ConsumptionOutput> {
		return MonarchClient.put(
			`${BASE_API_PREFIX}activity/${activityId}/consumption/${consumptionId}`,
			{
				json,
				...options,
			}
		).then((res) => res.json());
	}

	static async postActivityConsumptions(
		activityId: string,
		json: ConsumptionEditRequest
	): Promise<ConsumptionOutput> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}activity/${activityId}/consumptions`,
			{ json: { ...json, actual: !!json.actual } }
		).then((res) => res.json());
	}

	static async getSimpleFacilitiesConsumptionsDateRange(
		options?: Options
	): Promise<DateRangeResponse> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}entities-summary`,
			options
		).then((res) => res.json());
	}

	static async getEntitiesSummary(
		options?: Options
	): Promise<EntitiesResponse> {
		return MonarchClient.get(`${BASE_API_PREFIX}entities-summary`, {
			...options,
		}).then((res) => res.json());
	}

	static async getReports(options?: Options): Promise<Report[]> {
		return MonarchClient.get(`${BASE_API_PREFIX}reports`, options).then((res) =>
			res.json()
		);
	}

	static async getReport(
		report_uuid: string,
		options?: Options
	): Promise<Report> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}reports/${report_uuid}`,
			options
		).then((res) => res.json());
	}

	static async postReports(
		report: ReportRequest,
		options?: Options
	): Promise<Report> {
		return MonarchClient.post(`${BASE_API_PREFIX}reports`, {
			json: report,
			...options,
		}).then((res) => res.json());
	}

	static async deleteReport(report_uuid: string, options?: Options) {
		return MonarchClient.delete(
			`${BASE_API_PREFIX}reports/${report_uuid}`,
			options
		);
	}

	static async getDisclosures(
		report_uuid: string,
		options?: Options
	): Promise<Disclosure[]> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}reports/${report_uuid}/disclosures`,
			options
		).then((res) => res.json());
	}

	static async createDisclosures(
		report_uuid: string,
		disclosureInfo: CreateDisclosureRequest,
		options?: Options
	): Promise<Disclosure> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}reports/${report_uuid}/disclosures`,
			{ json: disclosureInfo, ...options }
		).then((res) => res.json());
	}

	static async deleteDisclosure(
		report_uuid: string,
		disclosure_uuid,
		options?: Options
	) {
		return MonarchClient.delete(
			`${BASE_API_PREFIX}reports/${report_uuid}/disclosures/${disclosure_uuid}`,
			options
		);
	}

	static async deleteActivities(options?: Options) {
		return MonarchClient.delete(`${BASE_API_PREFIX}activities`, options);
	}

	// maybe rename this deleteActivityConsumption?
	static async deleteConsumption(options?: Options) {
		return MonarchClient.delete(`${BASE_API_PREFIX}consumptions`, options);
	}

	static async deleteEmissionsFactors(options?: Options) {
		return MonarchClient.delete(`${BASE_API_PREFIX}emissions-factors`, options);
	}

	static async listSystemFlags(
		searchParams?: {
			period?: string;
			entity_pk: string; // Activity.uuid or Tag.name
			entity_type: FlagEntityType;
		} & PageSearchParams,
		options?: Options
	): Promise<Page<SystemFlag>> {
		return MonarchClient.get(`${BASE_API_PREFIX}system-flags`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getSystemFlagsSummary(
		searchParams?: {
			period?: string;
			scope?: string;
		},
		options?: Options
	): Promise<SystemFlagsSummaryResponse> {
		return MonarchClient.get(`${BASE_API_PREFIX}system-flags/summary`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async computeSystemFlags(
		searchParams: {
			period?: string; // eg: "year[2023]"
			entity_types?: FlagEntityType[];
			flag_types?: FlagType[];
			entity_ids?: string[]; // activity UUID or Tag.name
			use_worker?: boolean; // default false
		},
		options?: Options
	): Promise<any> {
		const preparedJson = prepareJson(searchParams);
		const result = await MonarchClient.post(
			`${BASE_API_PREFIX}system-flags/compute`,
			{
				...options,
				json: preparedJson,
			}
		);
		mutate(
			`/system-flags/summary?${prepareSearchParams(searchParams).toString()}`
		);
		return result;
	}

	/**
	 * used to get the total emissions summary
	 */
	static async getEmissionsHistory(
		searchParams?: EmissionHistorySearchParams,
		options?: Options
	): Promise<EmissionHistoryResponseItem[]> {
		return MonarchClient.get(`${BASE_API_PREFIX}emissions/history`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	/**
	 * used to get the emissions associated with a specific tag
	 */
	static async getTagEmissions(
		tagType: TagType,
		tagValue: string,
		searchParams?: TagEmissionsHistorySearchParams,
		options?: Options
	): Promise<EmissionHistoryResponseItem[]> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}tag/${tagType}/${tagValue}/emissions/history`,
			{
				...options,
				searchParams: prepareSearchParams(searchParams),
			}
		).then((res) => res.json());
	}

	/**
	 * Unused
	 * used to list tags along with their emissions data
	 */
	static async getTagsEmissionsHistory<T = Facility>(
		tagType: TagType,
		searchParams?: TagsEmissionsHistorySearchParams,
		options?: Options
	): Promise<Page<T>> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}tag/${tagType}/emissions/history`,
			{
				...options,
				searchParams: prepareSearchParams(searchParams),
			}
		).then((res) => res.json());
	}

	static async getTagEmissionsRankings(
		tagType: TagType,
		searchParams?: TagEmissionsRankingParams,
		options?: Options
	): Promise<TagEmissionsRankingResponse[]> {
		const res = await MonarchClient.get(
			`${BASE_API_PREFIX}tag/${tagType}/emissions/ranking`,
			{
				...options,
				searchParams: prepareSearchParams(searchParams),
			}
		);
		return res.json();
	}

	// Upsert supply chain definition of multiple suppliers
	static async upsertSupplyChainDefinition(
		year: string,
		supplyChainUploadData: any
	): Promise<VendorDetailsResponse> {
		return MonarchClient.post(
			`${SUPPLY_CHAIN_PREFIX}upsert_supply_chain_definition/${year}`,
			{
				json: supplyChainUploadData,
			}
		).then((res) => res.json());
	}

	// Upsert supplier details of multiple suppliers
	static async upsertSupplierDetails(
		year: string,
		supplierDetailsData: any
	): Promise<VendorDetailsResponse> {
		return MonarchClient.post(
			`${SUPPLY_CHAIN_PREFIX}upsert_supplier_details/${year}`,
			{
				json: supplierDetailsData,
			}
		).then((res) => res.json());
	}

	static async upsertCategory(
		emissionIntensityData: any
	): Promise<VendorDetailsResponse> {
		return MonarchClient.post(`${SUPPLY_CHAIN_PREFIX}commodity`, {
			json: emissionIntensityData,
		}).then((res) => res.json());
	}

	static async updateScopeEmissionsAllocations(
		name: string,
		year: number,
		emissionIntensityData: EmissionsAllocationItem
	): Promise<VendorDetailsResponse> {
		return MonarchClient.put(
			`${SUPPLY_CHAIN_PREFIX}supplier_details/${name}/${year}`,
			{
				json: emissionIntensityData,
			}
		).then((res) => res.json());
	}

	static async updateEmissionFactors(
		tagType: string, //category or supplier
		name: string,
		year: number,
		emissionIntensityData: any
	): Promise<VendorDetailsResponse> {
		return MonarchClient.put(
			`${SUPPLY_CHAIN_PREFIX}intensity/year/${year}/${tagType}/${name}`,
			{
				json: emissionIntensityData,
			}
		).then((res) => res.json());
	}

	static async getVendorCommodityDetailsV2(
		period: string,
		page: number = 1,
		page_size: number = 100,
		vendor?: string,
		commodity?: string
	): Promise<any> {
		return MonarchClient.get(
			`${SUPPLY_CHAIN_PREFIX}suppliers?period=${period}&vendor=${vendor || ""}&commodity=${commodity || ""}&page=${page}&page_size=${page_size}`
		).then((res) => res.json());
	}

	static async getSupplierDetails(
		supplier: string,
		period: string,
		options?: Options
	): Promise<SupplierDetailsResponse> {
		return MonarchClient.get(
			`${SUPPLY_CHAIN_PREFIX}suppliers/${supplier}?period=${period || ""}`,
			options
		).then((res) => res.json());
	}

	static async getInventoryPeriodByInterval(
		interval: InventoryPeriodInterval,
		options?: Options
	): Promise<InventoryPeriodItem[]> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}inventory-periods?interval=${interval}`,
			options
		).then((res) => res.json());
	}

	static async getSupplyChainListing(
		period_pk: string,
		group_by_type: string,
		page: number = 1,
		page_size: number = 100,
		vendor?: string
	): Promise<SupplyChainListingResponse> {
		return MonarchClient.get(
			`${SUPPLY_CHAIN_PREFIX}listing?period=${period_pk}&vendor=${vendor ? vendor : ""}&group_by=${group_by_type}&page=${page}&page_size=${page_size}`
		).then((res) => res.json());
	}

	static async getSupplyChainSummary(params: {
		period: string;
		vendor?: string; // unused?
		commodity?: string; // unused?
	}): Promise<SummaryData> {
		return MonarchClient.get(`${SUPPLY_CHAIN_PREFIX}summary`, {
			searchParams: prepareSearchParams(params),
		}).then((res) => res.json());
	}

	// This is to get list of 6 digit NAICS codes
	static async getNAICSCodes(options?: Options): Promise<NAICSCodesList> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}determinant/naics_code`,
			options
		).then((res) => res.json());
	}

	// This is to get the EF from the NAICS code
	static async getEmissionFactors(
		categoryKey: string,
		naics_code: string,
		options?: Options
	): Promise<NAICSEmissionFactorData> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}category/${categoryKey}/emissions-factors`,
			{ json: { naics_code: naics_code }, ...options }
		).then((res) => res.json());
	}

	static async getDeterminant(
		determinant_key: string,
		options?: Options
	): Promise<Determinant> {
		if (!determinant_key) {
			return Promise.resolve({} as Determinant);
		}
		return MonarchClient.get(
			`${BASE_API_PREFIX}determinant/${determinant_key}`,
			options
		).then((res) => res.json());
	}

	static async getDeterminants(options?: Options): Promise<Determinant[]> {
		return MonarchClient.get(`${BASE_API_PREFIX}determinants`, options).then(
			(res) => res.json()
		);
	}

	static async getCategoryDeterminants(
		category_key: string,
		options?: Options
	): Promise<CategoryDeterminants> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}category/${category_key}/determinants`,
			options
		).then((res) => res.json());
	}

	static async getEmissionFactorsInsights(
		json: EmissionFactorInsightsRequest,
		options?: Options
	): Promise<EmissionFactorInsightResponse> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}default-emissions-factors/insights`,
			{ json: prepareJson(json), ...options }
		).then((res) => res.json());
	}

	static async getConsumptionInsights(
		activity_id: string,
		json: ConsumptionInsightsRequest,
		options?: Options
	): Promise<ConsumptionInsightsResponse> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}activity/${activity_id}/consumptions/insights`,
			{ json: prepareJson(json), ...options }
		).then((res) => res.json());
	}

	static async getRenewable(
		id: string,
		searchParams?: {
			period_pk?: string;
		},
		options?: Options
	): Promise<RenewableResponse> {
		return MonarchClient.get(`${BASE_API_PREFIX}renewables/${id}`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getRenewables(
		searchParams?: {
			period_pk: string;
			sort_by?:
				| "quantity"
				| "-quantity"
				| "issued_at"
				| "-issued_at"
				| "location"
				| "-location"; // string;
		} & PageSearchParams,
		options?: Options
	): Promise<Page<RenewableResponse>> {
		return MonarchClient.get(`${BASE_API_PREFIX}renewables`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getRenewablesSummary(
		searchParams: RenewablesSummarySearchParams,
		options?: Options
	): Promise<RenewableSummaryResponse> {
		return MonarchClient.get(`${BASE_API_PREFIX}renewables/summary`, {
			...options,
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	/* Tags */

	static async getTagsSummaryByType(
		tagType: TagType,
		searchParams: {
			period: string;
			scopes: ("1" | "2" | "3")[];
		}
	): Promise<{
		emissions: {
			[key in EmissionMethodEnum]: UnitValue;
		};
	}> {
		return MonarchClient.get(`${BASE_API_PREFIX}tags/${tagType}/summary`, {
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async getTagsByType(
		tagType: TagType,
		searchParams?: GetTagsRequestParams
	): Promise<Page<Tag>> {
		const res = await MonarchClient.get(`${BASE_API_PREFIX}tags/${tagType}`, {
			searchParams: prepareSearchParams(searchParams),
		});
		const data: Page<Tag> = await res.json();
		if (Array.isArray(data)) {
			// TODO: This case handles the response from the old (not paginated) API, remove it once the API is updated
			return {
				items: data,
				meta: {
					total_records: data.length,
					page: 1, // API is 1-indexed
					page_size: data.length,
					pages: 1,
					links: {},
					tags: {},
				},
			};
		} else {
			return data;
		}
	}

	// this is used to delete sites and facilities
	static async deleteTags(tagType: TagType) {
		return MonarchClient.delete(`${BASE_API_PREFIX}tags/${tagType}`);
	}

	/**
	 * will permanently delete a tag, BEWARE!!
	 */
	static async deleteTag(tagType: TagType, tagValue: string) {
		return MonarchClient.delete(
			`${BASE_API_PREFIX}tags/${tagType}/${tagValue}`
		);
	}

	// this is used to get a single tag in detail
	static async getTag(
		tagType: TagType,
		tagValue: string,
		searchParams?: {
			period?: string;
		}
	): Promise<Tag> {
		return MonarchClient.get(`${BASE_API_PREFIX}tags/${tagType}/${tagValue}`, {
			searchParams: prepareSearchParams(searchParams),
		}).then((res) => res.json());
	}

	static async upsertTagMetrics(
		tagType: string,
		tagValue: string,
		metricName: string,
		metricValues: Metric["values"] | any // TODO: remove `any` here by defining shapes of values SCM uses
	): Promise<Metric> {
		return MonarchClient.put(
			`${BASE_API_PREFIX}tags/${tagType}/${tagValue}/metrics/${metricName}`,
			{ json: prepareJson(metricValues) }
		).then((res) => res.json());
	}

	static async upsertTagMetadata(
		tagType: string,
		tagValue: string,
		metadataValues: FacilityTagMetadata | GenericObject
	): Promise<Tag> {
		return MonarchClient.put(
			`${BASE_API_PREFIX}tags/${tagType}/${tagValue}/metadata`,
			{
				json: metadataValues,
			}
		).then((res) => res.json());
	}

	/**
	 * NOTE: only upsets tag_metadata in spite of method name and API path
	 */
	static async upsertTag(
		tagType: string,
		tagValue: string,
		metadataValues?: FacilityTagMetadata | GenericObject
	): Promise<Tag> {
		return MonarchClient.put(`${BASE_API_PREFIX}tags/${tagType}/${tagValue}`, {
			json: prepareJson(metadataValues || {}),
		}).then((res) => res.json());
	}

	/**
	 * Connect a tag to other tags, ex. connect a region to facilities, Tag.related_tags is a computed value, we can't update it directly so this is how to set it
	 * @param json a list of tags, behind the scene if those tags have activities those activities will get the tagValue added as a tag
	 */
	static async connectTags(
		tagType: string,
		tagValue: string,
		json: TagConnectionRequestItem[]
	): Promise<Tag> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}tags/${tagType}/${tagValue}/associate`,
			{
				json,
			}
		).then((res) => res.json());
	}

	/**
	 * Disconnect a tag from other tags, ex. remove a facility from a region. Tag.related_tags is a computed value, we can't update it directly so this is how to set it
	 * @param json a list of tags, behind the scene if those tags have activities those activities will get the tagValue removed as a tag
	 */
	static async disconnectTags(
		tagType: string,
		tagValue: string,
		json: TagConnectionRequestItem[]
	): Promise<Tag> {
		return MonarchClient.post(
			`${BASE_API_PREFIX}tags/${tagType}/${tagValue}/dissociate`,
			{
				json,
			}
		).then((res) => res.json());
	}

	static async getTagActivities(
		tagType: TagType,
		tagValue: string,
		searchParams?: {
			period?: string; // ^((year\[\d{4}\])|(quarter\[\d{4}\.0[1234]\])|(month\[\d{4}\.((0[1-9])|(1[0-2]))\])|(day\[\d{4}\.((0[1-9])|(1[0-2]))\.([0-3][0-9])\]))$
		} & PageSearchParams
	): Promise<Page<ActivityWithTags, ActivitiesListResponseMeta>> {
		return MonarchClient.get(
			`${BASE_API_PREFIX}tags/${tagType}/${tagValue}/activities`,
			{ searchParams: prepareSearchParams(searchParams) }
		).then((res) => res.json());
	}

	static async getOrganizationPreferences(): Promise<OrgPreferences> {
		return MonarchClient.get(`${BASE_API_PREFIX}preferences`).then((res) =>
			res.json()
		);
	}

	static async patchOrganizationPreferences(
		params: OrgPreferences
	): Promise<OrgPreferences> {
		const result = await MonarchClient.patch(`${BASE_API_PREFIX}preferences`, {
			json: params,
		});
		const json = await result.json();
		// Mutate inventory period select so that the months displayed are accurate.
		// These are a little redundant with the current page refresh implementation to grab all new numbers when GWP_VERSION is updated.
		mutate("/preferences", json);
		mutate("inventory-periods?interval=year");
		return json as OrgPreferences;
	}
}

const fallbackHistoryItem: EmissionHistoryResponseItem = {
	total_emission: 0,
	period_pk: "",
	starts_at: "",
	ends_at: "",
	breakdown: { scope: {} },
};

export const useInventoryPeriods = (
	interval: InventoryPeriodInterval = "year"
) => {
	const key = `inventory-periods?interval=${interval}`;
	const result = useSWR(
		key,
		() => EnergieClient.getInventoryPeriodByInterval(interval),
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
		}
	);
	swrErrorHandle({ key, result });
	return result;
};

export const useDeterminants = () => {
	const result = useSWRImmutable("/determinants", () =>
		EnergieClient.getDeterminants()
	);
	return result;
};

export const useDeterminant = (
	determinant_key
): {
	data?: Determinant;
	isLoading: boolean;
	error: any;
} => {
	const result = useDeterminants();
	return {
		...result,
		data: result.data?.find((it) => it.key === determinant_key),
	};
};

export const useCategoryDeterminants = (category_key) => {
	const key = `/category/${category_key}/determinants`;
	const result = useSWR(
		key,
		() => EnergieClient.getCategoryDeterminants(category_key),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData: {
				combinations: [],
				determinant_definitions: {},
			},
		}
	);
	return result;
};

export const useEmissionFactorsInsights = (params?) => {
	const key = `/default-emissions-factors/insights?${JSON.stringify(params)}`;
	const fallbackData: EmissionFactorInsightResponse = {
		outcome_code: 1,
		description: "",
		results: [],
	};
	const result = useSWR(
		key,
		() => EnergieClient.getEmissionFactorsInsights(params),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData,
		}
	);
	return result;
};

export const useRenewable = (
	id: string,
	searchParams?: {
		period_pk?: string;
	},
	swrOptions?: SWRConfiguration,
	kyOptions?: Options
) => {
	const key = id ? `/renewable/${id}?${slimObjectHash(searchParams)}` : null;
	const result = useSWR(
		key,
		() => EnergieClient.getRenewable(id, searchParams, kyOptions),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData: {
				allocation_details: {
					facility_allocations: [],
					total_activities: 0,
					total_allocated: 0,
					total_facilities: 0,
				},
				country_of_purchase: "",
				energy_type: "",
				issued_at: "",
				period_pk: "",
				project_name: "",
				quantity: 0,
				ref_id: "",
				type: null,
				unique_id: "",
				unit: "",
				purchase_group_name: "",
				effective_from: "",
				effective_to: "",
			},
			...swrOptions,
		}
	);
	swrErrorHandle({ key, params: searchParams, result });
	return result;
};

export const useRenewables = (searchParams?) => {
	const key = `/renewables?${new URLSearchParams(searchParams).toString()}`;
	const result = useSWR(key, () => EnergieClient.getRenewables(searchParams), {
		shouldRetryOnError: false,
		revalidateOnFocus: false,
	});
	return result;
};

export const useRenewablesSummary = (
	searchParams: RenewablesSummarySearchParams
) => {
	const key = searchParams.period_pk
		? `/renewables/summary?${new URLSearchParams(searchParams).toString()}`
		: null;
	const result = useSWR(
		key,
		() => EnergieClient.getRenewablesSummary(searchParams),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData: {
				period_pk: searchParams.period_pk ?? "year[1900]",
				tag_name: searchParams.tag_name,
				unit: "MWh",
				total_facilities: 0,
				facilities_with_renewables: 0,
				total_consumption: 0.0,
				renewable_consumption: 0.0,
				non_renewable_consumption: 0.0,
				renewable_impact_percentage: 0.0,
				facilities_with_renewables_percentage: 0.0,
			},
		}
	);
	return result;
};

export const useActivitiesSummary = (searchParams) =>
	useSWR(`/activities/summary?${slimObjectHash(searchParams)}`, () =>
		EnergieClient.getActivitiesSummary(searchParams)
	);

export const useActivity = (id, searchParams) => {
	const preparedSearchparams = prepareSearchParams(searchParams);
	const key = `/emissions/activity/${id}?${preparedSearchparams.toString()}`;
	const result = useSWR(
		key,
		() => {
			const response = EnergieClient.getActivity(id, searchParams);
			return response;
		},
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData: {
				uuid: "",
				ref_id: "",
				label: "",
				active_at: "",
				retired_at: "",
				category: "s1.1",
				tags: [],
				traits: {
					country: "",
					facility: "",
					facility_type: "",
					title: "",
				},
				consumption_stats: {
					modeled_days: 0,
					total_days: 0,
					breakdown: {},
				},
				emissions: {
					default: { in_period_total: null, unit: "", magnitude: null },
				},
				emissions_source: "",
				system_flags: [
					{
						uuid: "",
						organization_id: 0,
						period_pk: "",
						entity_pk: "",
						entity_type: "activities",
						flag_type: "missing_consumption",
						flag_data: {
							gaps: [],
							total_missing_days: 0,
							outcome_code: 1,
						},
						description: "",
						description_html: "",
					},
				],
			},
		}
	);
	return result;
};

export const useActivities = (params?, options?: SWRConfiguration) => {
	const key = `/emissions/activities?${new URLSearchParams(params).toString()}`;
	const result = useSWR(
		key,
		() => {
			const response = EnergieClient.getActivities(params);
			return response;
		},
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			...options,
		}
	);
	swrErrorHandle({ key, params, result });
	return result;
};

export const useActivityConsumptions = (
	id: string,
	searchParams?: {
		period?: string;
		include?: "emissions";
	},
	swrOptions?: SWRConfiguration,
	kyOptions?: Options
) => {
	const key =
		id && searchParams.period
			? `/emissions/activity/${id}/consumptions?${slimObjectHash(searchParams)}`
			: null;
	const result = useSWR(
		key,
		() => {
			const response = EnergieClient.getActivityConsumptions(
				id,
				searchParams,
				kyOptions
			);
			return response;
		},
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			...swrOptions,
		}
	);
	swrErrorHandle({ key, params: searchParams, result });
	return result;
};

export const useTagActivities = (
	tagType: TagType,
	tagValue: string,
	searchParams: {
		period: string; // ^((year\[\d{4}\])|(quarter\[\d{4}\.0[1234]\])|(month\[\d{4}\.((0[1-9])|(1[0-2]))\])|(day\[\d{4}\.((0[1-9])|(1[0-2]))\.([0-3][0-9])\]))$
	} & PageSearchParams,
	options?: SWRConfiguration
) => {
	const key =
		tagType && tagValue && searchParams.period
			? `/tags/${tagType}/${tagValue}/activities?${slimObjectHash(searchParams)}`
			: null;
	const result = useSWR(
		key,
		() => {
			const response = EnergieClient.getTagActivities(
				tagType,
				tagValue,
				searchParams
			);
			return response;
		},
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData: {
				items: [],
				meta: {
					total_records: 0,
					page: 1,
					page_size: 100,
					pages: 1,
					links: { first: "", last: "", self: "", next: "", prev: null },
				},
			},
			...options,
		}
	);
	swrErrorHandle({ key, params: searchParams, result });
	return result;
};

export const useTag = (
	tagType: TagType,
	tagValue: string,
	searchParams?: {
		period?: string;
	},
	options?: SWRConfiguration
) => {
	const key =
		tagType && tagValue
			? `/tag/${tagType}/${tagValue}?${slimObjectHash(searchParams)}`
			: null;
	const result = useSWR(
		key,
		() => EnergieClient.getTag(tagType, tagValue, searchParams),
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData: {
				name: `${tagType}:${tagValue}`,
				type: tagType,
				value: tagValue,
				// tag_metadata: { title: "", country: "", facility_type: "" },
				tag_metadata: {},
				metrics: [],
				related_tags: [],
			},
			...options,
		}
	);
	swrErrorHandle({ key, params: searchParams, result });

	return result;
};

export const useTags = (
	tagType: TagType,
	params?: GetTagsRequestParams,
	swrOptions?: SWRConfiguration,
	shouldFetch = true
) => {
	const key = `/tag/${tagType}?${slimObjectHash(params)}`;
	const result = useSWR<Page<Tag>>(
		!!tagType && shouldFetch ? key : null,
		() => EnergieClient.getTagsByType(tagType, params),
		{
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnMount: true,
			revalidateOnReconnect: false,
			...swrOptions,
		}
	);
	swrErrorHandle({ key, params, result });
	return result;
};

export const useTagsSummary = (tagType: TagType, params) => {
	const key = tagType
		? `/tag/${tagType}/summary?${slimObjectHash(params)}`
		: null;
	const result = useSWR(key, () =>
		EnergieClient.getTagsSummaryByType(tagType, params)
	);
	swrErrorHandle({ key, params, result });
	return result;
};

export const useMetrics = (
	searchParams?: {
		tag_name?: string;
		tag_type?: string;
	},
	options?: Options
) => {
	const key = `${BASE_API_PREFIX}metrics?${slimObjectHash(searchParams)}`; // `/tag/${tagType}?${slimObjectHash(params)}` ;
	const result = useSWR(
		key,
		() => {
			const response = EnergieClient.getMetrics(searchParams, options); // Promise<FacilityMetric[]>
			return response;
		},
		{
			shouldRetryOnError: false,
			revalidateIfStale: true,
			revalidateOnMount: true,
		}
	);
	swrErrorHandle({
		key,
		params: searchParams,
		result,
		expectArray: true,
		userFacingError: true,
	});

	return result;
};

export const useSystemFlagsList = (params?) => {
	const key = `/system-flags?${new URLSearchParams(params).toString()}`;
	const result = useSWR(key, () => EnergieClient.listSystemFlags(params), {
		shouldRetryOnError: false,
		revalidateOnFocus: false,
	});
	return result;
};

export const useSystemFlagsSummary = (params?) => {
	const key = `/system-flags/summary?${new URLSearchParams(params).toString()}`;
	const result = useSWR(
		key,
		() => EnergieClient.getSystemFlagsSummary(params),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
		}
	);
	swrErrorHandle({ key, params, result });
	return result;
};

export const useEmissionsHistory = (params: EmissionHistorySearchParams) => {
	const key = params?.period_pk
		? `/emissions/history/${slimObjectHash(params)}`
		: null;
	const result = useSWR(key, () => EnergieClient.getEmissionsHistory(params), {
		shouldRetryOnError: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
		revalidateIfStale: false,
		revalidateOnMount: true,
	});
	const { data } = result;
	swrErrorHandle({ key, result, params, expectArray: true });
	return { ...result, data: Array.isArray(data) ? data : [] };
};

// Unused - see getTagsEmissionsHistory
export const useTagsEmissionsHistory = (tagType: TagType, params) => {
	const key = params?.period_pk
		? `${BASE_API_PREFIX}tag/${tagType}/emissions/history?${slimObjectHash(params)}`
		: null;
	const result = useSWR(
		key,
		() => EnergieClient.getTagsEmissionsHistory(tagType, params),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
		}
	);
	swrErrorHandle({ key, result, params, expectArray: true });
	return result;
};

export const useTagEmissionsHistory = (
	tagType: TagType,
	tagValue,
	params: EmissionHistorySearchParams,
	options?: SWRConfiguration
) => {
	const key = params?.period_pk
		? `relay/energie/tag/${tagType}/${tagValue}/emissions/history${slimObjectHash(params)}`
		: null;
	const fallbackData = Array(length).fill(fallbackHistoryItem);
	const result = useSWR(
		key,
		() => EnergieClient.getTagEmissions(tagType, tagValue, params),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			fallbackData,
			...options,
		}
	);
	swrErrorHandle({
		key,
		params,
		result,
		expectArray: true,
		userFacingError: true,
	});
	return result;
};

export const useTagEmissionsRankings = (
	tagType: TagType,
	params: TagEmissionsRankingParams
) => {
	// `by` is a required param. if it's missing, don't make the API call.
	const key = !params?.by
		? null
		: `${BASE_API_PREFIX}tag/${tagType}/emissions/ranking${slimObjectHash(params)}`;
	const result = useSWR(
		key,
		() => EnergieClient.getTagEmissionsRankings(tagType, params),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			revalidateIfStale: false,
			revalidateOnMount: true,
		}
	);
	swrErrorHandle({
		key,
		params,
		result,
		expectArray: true,
		userFacingError: true,
	});
	return result;
};

export const useConsumption = (uuid, view) => {
	const shouldFetch = uuid && view === "Market-Based";

	const { data, error, isLoading } = useSWR(
		shouldFetch ? `/consumptions/${uuid}` : null,
		() =>
			EnergieClient.getConsumptionByUUID(uuid, {
				market_based: view === "Market-Based",
			}),
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			revalidateOnReconnect: false,
			revalidateIfStale: false,
			revalidateOnMount: true,
		}
	);

	return { data, error, isLoading };
};

export const useSupplyChainSummary = (
	params: {
		period: string;
		vendor?: string; // unused?
		commodity?: string; // unused?
	},
	options?: {
		shouldRetryOnError?: boolean;
		revalidateOnFocus?: boolean;
	}
) => {
	const key = params?.period
		? `/energie/supply-chain/summary/${slimObjectHash(params)}`
		: null;
	const result = useSWR(
		key,
		() => EnergieClient.getSupplyChainSummary(params),
		{
			shouldRetryOnError: options?.shouldRetryOnError ?? false,
			revalidateOnFocus: options?.revalidateOnFocus ?? false,
			fallbackData: {
				total: {
					tCO2e: 0,
					spend_usd: 0,
					emissions_intensity: 0,
				},
			},
		}
	);
	swrErrorHandle({ key, result });
	return result;
};

export const useSupplier = (
	supplier: string,
	period: string,
	options?: SWRConfiguration
) => {
	const key =
		period && supplier
			? `/energie/supply-chain/suppliers/${supplier}/${period}`
			: null;
	const fallbackData: SupplierDetailsResponse = {
		summary: {
			tCO2e: 0,
			spend_usd: 0,
			emissions_intensity: 0,
		},
		activities: [],
	};
	const result = useSWR<SupplierDetailsResponse>(
		key,
		() => EnergieClient.getSupplierDetails(supplier, period),
		{
			fallbackData,
			keepPreviousData: true,
			shouldRetryOnError: false,
			revalidateOnFocus: false,
			...options,
		}
	);
	swrErrorHandle({ key, result });
	return result;
};

export const useOrganizationPreferences = () => {
	const key = "/preferences";
	const result = useSWR(key, () => EnergieClient.getOrganizationPreferences());
	swrErrorHandle({ key, result });
	return result;
};

export default EnergieClient;
export { fallbackHistoryItem };
